import mutations from './mutations';
import Cookies from 'js-cookie';
import { currentAccount, deleteUser, getAllUsers, login, register, updateUser } from '../../../../services/api/user'
import Swal from 'sweetalert2'

const state = () => ({
  login: Cookies.get('logedIn'),
  loading: false,
  users: [{ username: "empty" }],
  filteredUsers: [],
  currentUser: null,
  error: null,
});

const actions = {
  async fetchUsers({ commit }) {
    try {
      const users = await getAllUsers();
      commit('fetchUserSuccess', users);
      console.log(users, 'users')
    } catch (err) {
      console.log(err)
    }
  },
  async decodeToken({ commit }) {
    try {
      const user = await currentAccount();
      commit('fetchAccountSuccess', user);
      console.log(user, 'user')
    } catch (err) {
      console.log(err)
    }
  },
  async signup({ commit }, payload) {
    try {
      const users = await register(payload);
      commit('fetchUserSuccess', users);
      console.log(users, 'users')
      Swal.fire({
        icon: 'success',
        title: 'User Added Successfully.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async editUser({ commit }, payload) {
    try {
      const users = await updateUser(payload.id, payload.data);
      commit('fetchUserSuccess', users);
      console.log(users, 'users')
      Swal.fire({
        icon: 'success',
        title: 'User Updated Successfully.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async removeUser({ commit }, id) {
    try {
      const users = await deleteUser(id);
      commit('fetchUserSuccess', users);
      console.log(users, 'users')
      Swal.fire({
        icon: 'success',
        title: 'User Deleted Successfully.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async login({ commit }, payload) {
    try {
      // commit('loginBegin', payload);
      const res = await login(payload)
      if (res) {
        console.log(res, 'login result')
        localStorage.setItem('access_token', res.token)
        commit('fetchAccountSuccess', res);
        Swal.fire({
          icon: 'success',
          title: 'Login Success',
          showConfirmButton: false,
          timer: 1500
        })
        Cookies.set('logedIn', true);
        return commit('loginSuccess', true);
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
      commit('loginErr', err.response.data.message);
    }
  },
  async logOut({ commit }) {
    try {
      commit('logoutBegin');
      Cookies.remove('logedIn');
      commit('logoutSuccess', null);
    } catch (err) {
      commit('logoutErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
