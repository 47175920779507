import axios from 'axios';
const https = require('https')
import { getItem } from '../../utility/localStorageControl';

console.log(process.env.VUE_APP_API_ENDPOINT)
export const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
// export const API_ENDPOINT = 'https://dx.tms-consulting.co.id';

const agent = new https.Agent({
  rejectUnauthorized: false,
  requestCert: false,
  agent: false,
});

export const client = axios.create({
    baseURL: API_ENDPOINT + '/api',
    httpsAgent: agent,
    headers: {
      Authorization: `Bearer ${getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
  });

  export const clientPublic = axios.create({
    baseURL: API_ENDPOINT + '/public',
    httpsAgent: agent,
    headers: {
      Authorization: `Bearer ${getItem('access_token')}`,
      'Content-Type': 'application/json',
    },
  });
