import { login } from '../../../../services/api/user'
import Swal from 'sweetalert2'
import fuzzysort from 'fuzzysort'

export default {
  async loginBegin(state, payload) {
    try {
      const res = await login(payload)
      if (res) {
        localStorage.setItem('access_token', res.token)
        Swal.fire({
          icon: 'success',
          title: 'Login Success',
          showConfirmButton: false,
          timer: 1500
        })
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  fetchUserSuccess(state, data) {
    state.users = data.filter(el => el._id !== state.currentAccount._id)
    state.filteredUsers = data.filter(el => el._id !== state.currentAccount._id)
    console.log(state.users, 'state users')
  },
  fetchAccountSuccess(state, data) {
    console.log(data, 'login data')
    state.currentAccount = data
  },
  loginSuccess(state, data) {
    state.loading = false;
    state.login = data;
    setTimeout(() => { window.location.reload(); }, 1000)
    
  },
  userSearch(state, data) {
    let rawUsersList = state.users
    if (data.length > 0) {
      console.log(data, 'user')
      const results = fuzzysort.go(data, rawUsersList, {key:'username'})
      console.log(results, 'results')
      const newData = []
      for (const assesment of results) {
        newData.push(assesment.obj)
      }
      newData.sort((a, b) => {
        if (a.bpmlCode < b.bpmlCode) {
          return -1;
        } else if (a.bpmlCode > b.bpmlCode) {
          return 1;
        } else {
          return 0;
        }
      });
      state.filteredUsers = newData
    } else {
      state.filteredUsers = rawUsersList
    }
  },

  loginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  logoutBegin(state) {
    state.loading = true;
  },

  logoutSuccess(state, data) {
    state.loading = false;
    state.login = data;
    state.currentUser = null;
    localStorage.removeItem("access_token")
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};
