import staticData from './projectData.json';
import mutations from './mutations';
import { addAssesmentToUser, addProjectToUser, checkSharepointApi, createIndustry, createProject, createProjectBpmls, createProjectComment, createProjectNonTemplate, deleteIndustry, deleteProject, deleteProjectComment, getBpmlL2s, getCommentByProject, getIndustries, getProject, getProjectBpmls, getProjectFiles, getProjects, getProjectsQuery, getProjectsQueryAdmin, getSfdtDocument, makeSharepointFolderApi, postProjectFile, removeAssesmentFromUser, removeProjectFromUser, updateIndustry, updateProject, uploadBpmlBody, uploadProjectFile } from '../../../services/api/project';
import Swal from 'sweetalert2';
import { message } from 'ant-design-vue';
import { addAssesments, deleteAssesment, deleteQuestion, deleteTest, getAssesmentBpmls, getAssesmentQuestions, getAssesments, getAssesmentsQuery, getCasesByBpml, getOneAssesment, getQuestionByBpml, getQuestions, getTestByCase, postCaseByBpml, postQuestion, uploadExcelQuestionApi, postTestByCase, updateAssesment, updateQuestion, updateTest, updateBpmlBodyFile, updateBpmlVideo, updateBpmlEut, uploadCaseTemplateApi, resetBpml, uploadExcelBpmlApi, updateCase, getAssesmentsQueryUser, getAssesmentsQueryAdmin } from '../../../services/api/bpml';

const state = () => ({
  data: staticData,
  singData: staticData,
  assesmentList: [],
  filteredAssesments: [],
  iframeLoading: false,
  iframeUpdate: false,
  iframefolderloaded: false,
  filteredIndustries: [],
  // assesmentList: [
  //   {
  //     assesmentId: '001',
  //     startDate: '02-12-2022',
  //     totalTime: '6 Days',
  //     project: [
  //       {
  //         projectId: 10011,
  //         category: ['Implementation', 'Kick Off'],
  //         projectDescription: 'PT SAP AXZ Implementation',
  //       }
  //     ],
  //     status: 'In Progress',
  //     company: "PT SAP AXZ Implementation",
  //     accountManager: 'Tesseract'
  //   },
  // ],
  singleData: {},
  commentsUpdate: 0,
  industries: [{}],
  projectData: [{}],
  filteredProjectData: [{}],
  selectedProject: {},
  assesmentType: true,
  projectType: true,
  selectedModule: "",
  projectListLoading: true,
  selectedL2: "",
  selectedAssesment: null,
  testCategory: "UAT",
  filteredBpmls: [],
  addAssesmentModal: false,
  updateAssesmentModal: false,
  assesmentQuestions: [],
  singleAssesment: null,
  bpmlQuestions: [],
  selectedAssesmentCode: null,
  bpmls: [],
  projectFiles: [],
  selectedLevel: [1],
  bpmlL2s: [],
  bpmlCases: [],
  bpmlTests: [],
  selectedBpml: null,
  selectedBpmlObj: null,
  selectedCase: "",
  selectedCaseObj: {},
  selectedCaseId: "",
  selectedBpmlId: "",
  selectedBpmlBodyPath: "",
  bpmlLoading: false,
  loading: false,
  sfdt: 'empty',
  error: null,
});

const actions = {
  async fetchProjects({ commit }) {
    try {
      const projects = await getProjects();
      commit('fetchProjectSuccess', projects);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchProjectFiles({commit}, project_id) {
    try {
      const projectFiles = await getProjectFiles(project_id)
      commit ('fetchProjectFilesSuccess', projectFiles)
    } catch (err) {
      console.log(err);
    }
  },
  async checkSharepointFile({commit}, data) {
    try {
      var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
      const res = await checkSharepointApi(data)
      window.open(`${data.localUrl}?csf=1&web=1&e=GBeZXq`, '_blank', strWindowFeatures)
      console.log(res, 'res')
      commit('')
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'Unavailable.',
        text: "File Not Found.",
      })
    }
  },
  async makeSharepointFolder({commit}, payload) {
    try {
      console.log(payload, 'payload run')
      message.loading('Loading/Refreshing BPML File Content', 9999)
      commit ('IFrameBefore');
      await makeSharepointFolderApi(payload.code, payload.module, payload.projectId, payload.bpml, payload.oldCode, payload.refresh, payload.newBpml, payload.bpmlId)
      message.destroy()
      if (payload.refresh == 'false') {
        commit('IFrameFolderUpdate', true)
        // await makeSharepointFolderApi(payload.code, payload.module, payload.projectId, payload.bpml, payload.oldCode, 'true', payload.newBpml, payload.bpmlId)
      } else {
        message.success('BPML File Loaded.')
        commit('IFrameFolderUpdate', false)
      }
      // Swal.fire({
      //   icon: 'success',
      //   title: 'BPML File Loaded.',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
      commit('iFrameUpdate');
    } catch (err) {
      console.log(err);
      message.destroy()
      message.error('BPML File Not Found.')
    }
  },
  async uploadProjectFile({commit}, data) {
    try {
      console.log(data, 'formdata')
      const projectFiles = await postProjectFile(data)
      commit ('fetchProjectFilesSuccess', projectFiles)
      Swal.fire({
        icon: 'success',
        title: 'File Successfully Uploaded.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err);
    }
  },
  async uploadExcelQuestions({ commit }, payload) {
    try {
      const response = await uploadExcelQuestionApi(payload);
      commit('fetchQuestionsSuccess', response);
      // message.success('Template uploaded successfully.')
      Swal.fire({
        icon: 'success',
        title: 'Template Successfully Uploaded.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (error) {
      console.error(error);
    }
  },
  async uploadExcelBpmls({ commit }, payload) {
    try {
      const response = await uploadExcelBpmlApi(payload);
      commit('', response);
      // message.success('Template uploaded successfully.')
      Swal.fire({
        icon: 'success',
        title: 'Template Successfully Uploaded.',
        showConfirmButton: false,
        timer: 1500
      })
      // window.location.reload();
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Network Error: If this message occurs, please upload the file again.",
      })
    }
  },
  async uploadCaseTemplate({ commit }, payload) {
    try {
      await uploadCaseTemplateApi(payload.formData);
      // commit('fetchCasesSuccess', response.cases);
      // commit('fetchTestsSuccess', response.tests);
      // commit('fetchUploadCaseSuccess', response.cases);
      // message.success('Template uploaded successfully.')
      commit('')
      Swal.fire({
        icon: 'success',
        title: 'Template Successfully Uploaded.',
        showConfirmButton: false,
        timer: 1500
      })

    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.message,
      })
      console.error(error);
    }
  },
  async fetchAssesmentQuestions({ commit }, payload) {
    try {
      const questions = await getAssesmentQuestions(payload.assesment_id, payload.code, payload.level, payload.type, payload.project_id);
      console.log(questions, 'questions')
      commit('fetchAssesmentQuestionsSuccess', questions);
    } catch (err) {
      console.log(err)
    }
  },
  async uploadBpmlDocx ({ commit }, formData) {
    try {
      commit('')
      await uploadBpmlBody(formData)
      // message.success('Document saved successfully');
    } catch (err) {
      message.error('Failed to save document.');
      console.log(err)
    }
  },
  async uploadFile ({ commit }, formData) {
    try {
      commit('')
      await uploadProjectFile(formData)
      // message.success('Document saved successfully');
    } catch (err) {
      message.error('Failed to save document.');
      console.log(err)
    }
  },
  async updateVideo ({ commit }, payload) {
    try {
      commit('')
      await updateBpmlVideo(payload.id, payload.data)
      commit('updateBpmlVideoSuccess', payload.data)
      // message.success('Document saved successfully');
    } catch (err) {
      message.error('Failed to save document.');
      console.log(err)
    }
  },
  async updateEut ({ commit }, payload) {
    try {
      commit('')
      await updateBpmlEut(payload.id, payload.data)
      commit('updateBpmlEutSuccess', payload.data)
      // message.success('Document saved successfully');
    } catch (err) {
      message.error('Failed to save document.');
      console.log(err)
    }
  },
  async updateBpmlBody ({ commit }, payload) {
    try {
      console.log('run')
      await updateBpmlBodyFile(payload.code, payload.data)
      commit('updateBpmlBodySuccess', payload.data)
      // message.success('Document saved successfully');
    } catch (err) {
      message.error('Failed to save document.');
      console.log(err)
    }
  },
  async fetchProjectBpmls({ commit }, project_id, event) {
    try {
      commit('fetchBpmlProjectBegin');
      const bpmls = await getProjectBpmls(project_id, event);
      commit('fetchBpmlProjectSuccess', bpmls);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchProject({ commit }, id) {
    try {
      const project = await getProject(id);
      commit('fetchOneProject', project);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchAssesment({ commit }, id) {
    try {
      const assesment = await getOneAssesment(id);
      commit('fetchOneAssesment', assesment);
    } catch (err) {
      console.log(err)
    }
  },
  async resetBpmlTemplate({ commit }, code) {
    try {
      console.log(code, 'code')
      await resetBpml(code);
      commit('');
      window.location.reload();
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async postIndustry({ commit }, payload) {
    try {
      const industries = await createIndustry(payload);
      commit('fetchIndustriesSuccess', industries);
      Swal.fire({
        icon: 'success',
        title: 'Industry Successfully Added.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async editIndustry({ commit }, payload) {
    try {
      const industries = await updateIndustry(payload.id, payload.data);
      commit('fetchIndustriesSuccess', industries);
      Swal.fire({
        icon: 'success',
        title: 'Industry Successfully Updated.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  industrySearchAction({ commit }, industry) {
    commit('industrySearch', industry)
  },
  async removeIndustry({ commit }, id) {
    try {
      const industries = await deleteIndustry(id);
      commit('fetchIndustriesSuccess', industries);
      Swal.fire({
        icon: 'success',
        title: 'Industry Successfully Deleted.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async fetchIndustries({ commit }) {
    try {
      const industries = await getIndustries();
      commit('fetchIndustriesSuccess', industries);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchAssesmentBpmls({ commit }, code) {
    try {
      const bpmls = await getAssesmentBpmls(code);
      console.log(bpmls, 'bpmls?')
      commit('fetchBpmlProjectSuccess', bpmls);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchBpmlL2s({ commit }) {
    try {
      const bpmlL2s = await getBpmlL2s();
      commit('fetchBpmlL2s', bpmlL2s);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchCasesByBpml({ commit }, payload) {
    try {
      console.log(payload, "case run")
      const cases = await getCasesByBpml(payload.bpml_id, payload.event);
      commit('fetchCasesSuccess', cases);
      console.log(cases, 'kasus')
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
    }
  },
  async createCaseByBpml({ commit }, data) {
    try {
      const cases = await postCaseByBpml(data);
      commit('fetchCasesSuccess', cases);
      Swal.fire({
        icon: 'success',
        title: 'Case Successfully Added.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async editCase({ commit }, payload) {
    try {
      const cases = await updateCase(payload.id, payload.data);
      commit('fetchCasesSuccess', cases);
      Swal.fire({
        icon: 'success',
        title: 'Case Successfully Updated.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async createTestByBpmlCase({ commit }, data) {
    try {
      const tests = await postTestByCase(data);
      commit('fetchTestsSuccess', tests);
      Swal.fire({
        icon: 'success',
        title: 'Test Successfully Added.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async createQuestion({ commit }, data) {
    try {
      const questions = await postQuestion(data);
      commit('fetchAssesmentQuestionsSuccess', questions);
      Swal.fire({
        icon: 'success',
        title: 'Question Successfully Added.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async fetchQuestions ({ commit }) {
    try {
      const questions = await getQuestions();
      commit('fetchQuestionsSuccess', questions);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchAssesments ({ commit }) {
    try {
      const assesments = await getAssesments();
      commit('fetchAssesmentsSuccess', assesments);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchAssesmentsQueryUser ({ commit }, payload) {
    try {
      const projects = await getAssesmentsQueryUser(payload.isTemplate, payload.userId);
      commit('fetchAssesmentsSuccess', projects);
    } catch (err) {
      console.log(err)
    }
  },
   async fetchAssesmentsQueryAdmin ({ commit }, payload) {
    try {
      const projects = await getAssesmentsQueryAdmin(payload.isTemplate, payload.userId);
      commit('fetchAssesmentsSuccess', projects);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchProjectsQuery ({ commit }, payload) {
    try {
      const projects = await getProjectsQuery(payload.isTemplate, payload.userId);
      commit('fetchProjectSuccess', projects);
    } catch (err) {
      console.log(err)
    }
  },
   async fetchProjectsQueryAdmin ({ commit }, payload) {
    try {
      const projects = await getProjectsQueryAdmin(payload.isTemplate, payload.userId);
      commit('fetchProjectSuccess', projects);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchAssesmentsQuery ({ commit }, isTemplate) {
    try {
      const assesments = await getAssesmentsQuery(isTemplate);
      commit('fetchAssesmentsSuccess', assesments);
    } catch (err) {
      console.log(err)
    }
  },
  async fetchTestsByBpmlCase({ commit }, bpmlCase_id) {
    try {
      const tests = await getTestByCase(bpmlCase_id);
      commit('fetchTestsSuccess', tests);
      console.log(tests, 'tests')
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
    }
  },
  async updateTestsByBpmlCase({ commit }, payload) {
    try {
      const tests = await updateTest(payload.id, payload.data);
      commit('fetchTestsSuccess', tests);
      console.log(tests, 'tests')
      Swal.fire({
        icon: 'success',
        title: 'Test Successfully Updated.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async editAssesment({ commit }, payload) {
    try {
      const assesmentsObj = await updateAssesment(payload.id, payload.data);
      console.log(assesmentsObj, 'assobj')
      commit('fetchAssesmentsSuccess', assesmentsObj);
      for (const member of payload.data.members) {
        const assesments = [{ 
          assesment_id: payload.id
        }]
        const formData = { assesments }
        await addAssesmentToUser(member, formData)
      }
      for (const member of payload.data.removeMembers) {
        const assesments = [{ 
          assesment_id: payload.id
        }]
        const formData = { assesments }
        await removeAssesmentFromUser(member, formData)
      }
      console.log(assesmentsObj, 'questions')
      Swal.fire({
        icon: 'success',
        title: 'Assesment Successfully Updated.',
        showConfirmButton: false,
        timer: 1500
      })
      setTimeout(() => { window.location.reload(); }, 1000)
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async removeAssesment({ commit }, id) {
    try {
      const assesments = await deleteAssesment(id);
      commit('fetchAssesmentsSuccess', assesments);
      console.log(assesments, 'questions')
      Swal.fire({
        icon: 'success',
        title: 'Assesment Successfully Deleted.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async editQuestion({ commit }, payload) {
    try {
      const questions = await updateQuestion(payload.id, payload.data);
      commit('fetchAssesmentQuestionsSuccess', questions);
      console.log(questions, 'questions')
      Swal.fire({
        icon: 'success',
        title: 'Question Successfully Updated.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async removeQuestion({ commit }, payload) {
    try {
      const questions = await deleteQuestion(payload.id, payload.data);
      commit('fetchAssesmentQuestionsSuccess', questions);
      console.log(questions, 'questions')
      Swal.fire({
        icon: 'success',
        title: 'Question Successfully Deleted.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async editProject({ commit }, payload) {
    try {
      const projects = await updateProject(payload.id, payload.data);
      commit('fetchProjectSuccess', projects);
      console.log(projects, 'projects')
      for (const member of payload.data.members) {
        const projects = [{ 
          project_id: payload.id
        }]
        const formData = { projects }
        await addProjectToUser(member, formData)
      }
      for (const member of payload.data.removeMembers) {
        const projects = [{ 
          project_id: payload.id
        }]
        const formData = { projects }
        await removeProjectFromUser(member, formData)
      }
      Swal.fire({
        icon: 'success',
        title: 'Project Successfully Updated.',
        showConfirmButton: false,
        timer: 1500
      })
    setTimeout(() => { window.location.reload(); }, 1000)
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async removeProjectComment({ commit }, id) {
    try {
      const comments = await deleteProjectComment(id);
      commit('fetchCommentsSuccess', comments);
      console.log(comments, 'comments')
      message.success("Comments deleted successfully.")
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Comment Successfully Updated.',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async addProjectComment({ commit }, data) {
    try {
      const comments = await createProjectComment(data);
      commit('fetchCommentsSuccess', comments);
      console.log(comments, 'commentsaction')
      message.success("Comments has been updated.")
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Comment Successfully Updated.',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async fetchCommentsByProject({ commit }, payload) {
    try {
      console.log(payload, 'commeny payload')
      const comments = await getCommentByProject(payload.project, payload.bpml);
      commit('fetchCommentsSuccess', comments);
      console.log(comments, 'comments')
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Questions Successfully Updated.',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
    } catch (err) {
      console.log(err)
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Oops...',
      //   text: err.response.data.message,
      // })
    }
  },
  async fetchQuestionsByBpml({ commit }, bpml_id) {
    try {
      const questions = await getQuestionByBpml(bpml_id);
      commit('fetchQuestionsSuccess', questions);
      console.log(questions, 'questions')
      // Swal.fire({
      //   icon: 'success',
      //   title: 'Questions Successfully Updated.',
      //   showConfirmButton: false,
      //   timer: 1500
      // })
    } catch (err) {
      console.log(err)
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Oops...',
      //   text: err.response.data.message,
      // })
    }
  },
  async removeProject({ commit }, payload) {
    try {
      const projects = await deleteProject(payload.id, payload.data);
      commit('fetchProjectSuccess', projects);
      console.log(projects, 'projects')
      Swal.fire({
        icon: 'success',
        title: 'Project Successfully Deleted.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async deleteTestInCase({ commit }, payload) {
    try {
      const tests = await deleteTest(payload.id, payload.data);
      commit('fetchTestsSuccess', tests);
      console.log(tests, 'tests')
      Swal.fire({
        icon: 'success',
        title: 'Test Successfully Deleted.',
        showConfirmButton: false,
        timer: 1500
      })
    } catch (err) {
      console.log(err)
      commit('fetchCasesFailed')
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async insertProject({ commit }, data) {
    try {
      commit('fetchBpmlProjectBegin');
      const project = await createProject(data)
      commit('addProjectSuccess', project);
      Swal.fire({
        icon: 'success',
        title: 'Project Successfully Created.',
        showConfirmButton: false,
        timer: 1500
      })
      console.log(project)
    } catch (err) {
      console.log(err)
    }
  },
  async insertProjectNonTemplate({ commit }, data) {
    try {
      Swal.fire({
        title: 'Inserting Project Data, please wait..',
        timer: 3000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
        },
        willClose: () => {
        }
      }).then(() => {
      })
      commit('fetchBpmlProjectBegin');
      const project = await createProjectNonTemplate(data)
      commit('addProjectSuccess', project);
      // await addProjectToUser(member, formData)
      for (const member of data.members) {
        const projects = [{ 
          project_id: project._id
        }]
        const formData = { projects }
        await addProjectToUser(member, formData)
      }
      Swal.fire({
        icon: 'success',
        title: 'Project Successfully Created.',
        showConfirmButton: false,
        timer: 1500
      })
      console.log(project)
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async insertAssesment({ commit }, data) {
    try {
      const assesmentsObj = await addAssesments(data)
      console.log(assesmentsObj, 'assesmentsuccess')
      commit('insertAssesmentsSuccess', assesmentsObj);
      for (const member of data.members) {
        const assesments = [{ 
          assesment_id: assesmentsObj.assesment._id
        }]
        console.log(assesments, 'assesments')
        const formData = { assesments }
        await addAssesmentToUser(member, formData)
      }
      Swal.fire({
        icon: 'success',
        title: 'Assesment Successfully Created.',
        showConfirmButton: false,
        timer: 1500
      })
      console.log(assesmentsObj)
    } catch (err) {
      console.log(err)
    }
  },
  async insertProjectBpmls({ commit }, data) {
    try {
      commit('fetchBpmlProjectBegin');
      const bpmls = await createProjectBpmls(data)
      commit('fetchBpmlProjectSuccess', bpmls);
      Swal.fire({
        icon: 'success',
        title: 'New BPML Generated',
        showConfirmButton: false,
        timer: 1500
      })
      window.location.reload();
      console.log(bpmls)
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.response.data.message,
      })
    }
  },
  async loadSfdtDocument({ commit }, path) {
    try {
      const sfdt = await getSfdtDocument(path);
      commit('loadSfdtSuccess', sfdt)
      console.log(sfdt)
    } catch (err) {
      console.log(err)
      const sfdt =  {
              "sections": [
                  {
                      "blocks": [
                          {
                              "inlines": [
                                  {
                                      "characterFormat": {
                                          "bold": true,
                                          "italic": true
                                      },
                                      "text": ""
                                  }
                              ]
                          }
                      ],
                      "headersFooters": {
                      }
                  }
              ]
      };
      commit('loadSfdtSuccess', sfdt)
    }
  },
  filterBpmlByL2({ commit }, bpmlL2) {
    commit('filterByL2', bpmlL2)
  },
  projectSearchAction({ commit }, project) {
    commit('projectSearch', project)
  },
  assesmentSearchAction({ commit }, assesment) {
    commit('assesmentSearch', assesment)
  },
  filterByBpml({ commit }, bpml) {
    commit('filterByBpml', bpml)
  },
  filterByModules({commit}, module) {
    commit('filterByModules', module)
  },
  async filterSinglePage({ commit }, paramsId) {
    try {
      commit('singleProjectBegin');
      const data = staticData.filter((project) => {
        return project.id === parseInt(paramsId, 10);
      });
      commit('singleProjectSuccess', data);
    } catch (err) {
      commit('singleProjectErr', err);
    }
  },
  async filterProjectByStatus({ commit }, status) {
    try {
      commit('filterProjectBegin');
      const data = staticData.filter((project) => {
        if (status !== 'all') {
          return project.status === status;
        }
        return staticData;
      });
      commit('filterProjectSuccess', data);
    } catch (err) {
      commit('filterProjectErr', err.toString());
    }
  },

  async sortingProjectByCategory({ commit }, sortBy) {
    try {
      commit('sortingProjectBegin');
      const data = staticData.sort((a, b) => {
        return b[sortBy] - a[sortBy];
      });
      setTimeout(() => {
        commit('sortingProjectSuccess', data);
      }, 500);
    } catch (err) {
      commit('sortingProjectErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
