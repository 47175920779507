export default [
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/projects/Project.vue'),
  },
  {
    path: '/project/detail/:id',
    name: 'projectDetail',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/projects/ProjectDetails.vue'),
  },
  // Assesments
  {
    path: '/assesments',
    name: 'assesments',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/assesments/Assesments.vue'),
  },
  {
    path: '/assesments/detail/:id/:code',
    name: 'assesmentsDetail',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/assesments/AssesmentDetails.vue'),
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Maintenance.vue'),
  },
  {
    path: '/user-management',
    name: 'user-management',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/user-management/UserManagement.vue'),
  },
  {
    path: '/master-data',
    name: 'master-data',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/masterData/MasterData.vue'),
  },
  {
    path: '/master-data/detail/:id/:code',
    name: 'master-data-detail',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/masterData/MasterDataDetails.vue'),
  },
];
