import { createWebHistory, createRouter } from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './authRoutes';
import store from '@/vuex/store';
// import { message } from 'ant-design-vue';
// import { currentAccount } from '@/services/api/user';

const routes = [
  {
    name: 'Admin',
    path: '/',
    component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
    children: [...adminRoutes],
    meta: { auth: false },
  },
  {
    name: 'Auth',
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
    children: [...authRoutes],
    meta: { auth: true },
  },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_SUB_ROUTE
        ? process.env.VUE_APP_SUB_ROUTE
        : process.env.BASE_URL
      : process.env.BASE_URL,
  ),
  linkExactActiveClass: 'active',
  routes,
});

// const verifyLogin = async () => {
//   try {
//     const data = await currentAccount();
//     store.state.auth.login = data
//     return true
//   } catch (err) {
//     return false
//   }
// }

const loadpage = () => {

  store.dispatch('decodeToken')
  // const hide = message.loading('Loading page, please wait...', 0);
  // setTimeout(hide, 2500);
};

router.beforeEach(async (to, from, next) => {
  // const isLoggedIn = await verifyLogin()
  if (store.state.auth.login && to.fullPath === '/') {
    console.log(to.fullPath, 'fullpath')
    next({ name: 'projects' });
  } else if (!store.state.auth.login && to.fullPath === '/') {
    next({ name: 'login' });
  } else {
    console.log('alles')
    loadpage();
    next();
  }
});

export default router;
