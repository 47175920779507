import { client } from './axiosConfig'
const token = localStorage.getItem('access_token')
const config = {
  headers: {
    token: token,
  },
}

const getAllUsers = async () => {
  const res = await client.get('/users', config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const login = async (data) => {
  const res = await client.post('/users/login', data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const register = async (data) => {
  const res = await client.post('/users/register', data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateUser = async (id, data) => {
  const res = await client.put('/users/updateUser/' + id, data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const deleteUser = async (id) => {
  const res = await client.delete('/users/deleteUser/' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const currentAccount = async () => {
    const res = await client.get('/users/currentAccount', config)
    if (res) {
      return res.data
    } else {
      return []
    }
}

export {
    getAllUsers,
    login,
    currentAccount,
    updateUser,
    deleteUser,
    register,
}
