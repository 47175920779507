import { client, clientPublic } from './axiosConfig'
const token = localStorage.getItem('access_token')
const config = {
  headers: {
    token: token,
    authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvdHJpbWl0cmFzaXN0ZW1zb2x1c2luZG8uc2hhcmVwb2ludC5jb21ANGM2NWI0ZmItYTRmZS00YzI0LWFiYmUtNTYxODg2ZGQ5ZTc3IiwiaXNzIjoiMDAwMDAwMDEtMDAwMC0wMDAwLWMwMDAtMDAwMDAwMDAwMDAwQDRjNjViNGZiLWE0ZmUtNGMyNC1hYmJlLTU2MTg4NmRkOWU3NyIsImlhdCI6MTY3MjM5MTc1MiwibmJmIjoxNjcyMzkxNzUyLCJleHAiOjE2NzI0MjA4NTIsImFjdG9yIjoiM2I3MGIxZTMtOGE0Ny00YzJkLTg2NzctMzRmNmJiMzE2M2M2QDRjNjViNGZiLWE0ZmUtNGMyNC1hYmJlLTU2MTg4NmRkOWU3NyIsImlkZW50aXR5cHJvdmlkZXIiOiJ1cm46ZmVkZXJhdGlvbjptaWNyb3NvZnRvbmxpbmUiLCJuYW1laWQiOiIxMDAzMjAwMjYwMEIxODlBIn0.rCEgJq07UkfLfy_gfbsAduyJ64vG9YCAL1SFeyfFZlRGNbYi0KMwyI1dhJbU_QJFivTMVrGobMN7QwkfbZj5yheliq0t960sF1MWJoXuiNIqeco1XkKqLa2kN4MoH4PplIBqHyr0uFYOHtPF1PRdCTTyk8bt1x-7gjrONSc5exFjXOEnXryPHbQYDEL8xBOV7DG0x7IuDp5pz9R9A8fFWejiB56tM28RWR52np1s2WXpjKWDE9BxLz8kbWCAjaTxHzKJQk-vHSJYbOql3VEMTyLKDt1WBxs_ehE8rJj1o9GqR3XB9z2UXx3mS5G9vAxnAcx1cTOdeZt1K8Dm9fpznQ'
  },
}

const getProjects = async () => {
  const res = await client.get('/projects', config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getProjectsQuery = async (isTemplate, userId) => {
  const res = await client.get(`/projects/query?isTemplate=${isTemplate}&userId=${userId}`, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getProjectsQueryAdmin = async (isTemplate, userId) => {
  const res = await client.get(`/projects/queryAdmin?isTemplate=${isTemplate}&userId=${userId}`, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getProjectFiles = async (project_id) => {
  const res = await client.get('/projects/files/byProject/' + project_id, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const postProjectFile = async (data) => {
  const res = await client.post('/upload/project/files', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateProject = async (id, data) => {
  const res = await client.put('/projects/update/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getProjectBpmls = async (project_id) => {
  const res = await client.get('/bpmlsProjects/' + project_id, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const uploadBpmlBody = async (formData) => {
  const res = await client.post('/upload/bpmlBody', formData, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const uploadProjectFile = async (formData) => {
  const res = await client.post('/upload/projectFile', formData, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getBpmlL2s = async () => {
  const res = await client.get('/bpmlsProjects/L2/list', config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getProject = async (id) => {
  const res = await client.get('/projects/' + id, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getIndustries = async () => {
  const res = await client.get('/industries', config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateIndustry = async (id, data) => {
  const res = await client.put('/industries/update/' + id, data)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const deleteIndustry = async (id) => {
  const res = await client.delete('/industries/delete/' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const createIndustry = async (data) => {
  const res = await client.post('/industries/create', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const createProject = async (data) => {
  const res = await client.post('/projects/create', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const createProjectNonTemplate = async (data) => {
  const res = await client.post('/projects/createNonTemplate', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const checkSharepointApi = async (data) => {
  const res = await client.post('/projects/sharepoint/check', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const makeSharepointFolderApi = async (code, module, projectId, bpml, oldCode, refresh, newBpml, bpmlId) => {
  console.log(code, 'code value')
  const res = await client.get(`/projects/sharepoint/folder?module=${code}&submodule=${module}&projectId=${projectId}&bpml=${bpml}&oldCode=${oldCode}&refresh=${refresh}&newBpml=${newBpml}&bpmlId=${bpmlId}`, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getCommentByProject = async (id, bpml) => {
  const res = await client.get(`/comments/project/${id}/${bpml}`, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const addAssesmentToUser = async (id, data) => {
  const res = await client.put('/users/addAssesment/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const removeAssesmentFromUser = async (id, data) => {
  const res = await client.put('/users/removeAssesment/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const addProjectToUser = async (id, data) => {
  const res = await client.put('/users/addProject/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const removeProjectFromUser = async (id, data) => {
  const res = await client.put('/users/removeProject/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const createProjectComment = async (data) => {
  const res = await client.post('/comments/create', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const deleteProjectComment = async (id) => {
  const res = await client.delete('/comments/delete/' + id, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const createProjectBpmls = async (data) => {
  const res = await client.post('/bpmls/createBulk', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const deleteProject = async (id, data) => {
  const res = await client.put('/projects/delete/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getSfdtDocument = async (path) => {
  // const res = await clientPublic.get('/10001/MNG-OS-0020/sample.sfdt')
  const res = await clientPublic.get(path)
  if (res) {
    return res.data
  } else {
    return []
  }
}

export {
    getProjects,
    deleteProject,
    getProject,
    getProjectBpmls,
    createProject,
    updateProject,
    uploadBpmlBody,
    addAssesmentToUser,
    getCommentByProject,
    getBpmlL2s,
    uploadProjectFile,
    getProjectsQuery,
    createProjectComment,
    getSfdtDocument,
    checkSharepointApi,
    getProjectFiles,
    getProjectsQueryAdmin,
    addProjectToUser,
    removeProjectFromUser,
    createIndustry,
    removeAssesmentFromUser,
    updateIndustry,
    deleteIndustry,
    createProjectBpmls,
    postProjectFile,
    deleteProjectComment,
    makeSharepointFolderApi,
    getIndustries,
    createProjectNonTemplate,
}
