import { client } from './axiosConfig'
const token = localStorage.getItem('access_token')
const config = {
  headers: {
    token: token,
  },
}

const getCasesByBpml = async (bpml_id, event) => {
  const res = await client.get(`/bpmls/cases/${bpml_id}?event=${event}`, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getQuestionByBpml = async (bpml_id) => {
  const res = await client.get('/bpmls/questions/' + bpml_id, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getQuestions = async () => {
  const res = await client.get('/bpmls/questions', config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAssesments = async () => {
  const res = await client.get('/bpmls/assesments', config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAssesmentsQuery = async (isTemplate) => {
  const res = await client.get('/bpmls/assesments/query?isTemplate=' + isTemplate, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAssesmentsQueryUser = async (isTemplate, userId) => {
  const res = await client.get(`/bpmls/assesments/queryUser?isTemplate=${isTemplate}&userId=${userId}`, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAssesmentsQueryAdmin = async (isTemplate, userId) => {
  const res = await client.get(`/bpmls/assesments/queryAdmin?isTemplate=${isTemplate}&userId=${userId}`, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAssesmentQuestions = async (assesment, code, level, type, project) => {
  const res = await client.get(`/bpmls/questions/assesment?assesment=${assesment}&code=${code}&level=${level}&type=${type}&project=${project}`, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getOneAssesment = async (id) => {
  const res = await client.get('/bpmls/assesments/' + id, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getAssesmentBpmls = async (code) => {
  const res = await client.get('/bpmls/assesments/code/' + code, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const addAssesments = async (data) => {
  const res = await client.post('/bpmls/assesments/create', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateQuestion = async (id, data) => {
  const res = await client.put('/bpmls/questions/update/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateAssesment = async (id, data) => {
  const res = await client.put('/bpmls/assesments/update/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const deleteAssesment = async (id) => {
  const res = await client.put('/bpmls/assesments/delete/' + id, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const postQuestion = async (data) => {
  const res = await client.post('/bpmls/questions/create', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const postCaseByBpml = async (data) => {
  const res = await client.post('/bpmls/cases/create', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateCase = async (id, data) => {
  const res = await client.put('/bpmls/cases/update/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const postTestByCase = async (data) => {
  const res = await client.post('/bpmls/tests/create', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const getTestByCase = async (bpmlCase_id) => {
  const res = await client.get('/bpmls/tests/' + bpmlCase_id, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateTest = async (id, data) => {
  const res = await client.put('/bpmls/tests/update/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateBpmlBodyFile = async (id, data) => {
  const res = await client.put('/bpmls/update/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateBpmlVideo = async (id, data) => {
  const res = await client.put('/bpmls/update/video/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const uploadExcelQuestionApi = async (data) => {
  const res = await client.post('/upload/excel/questions', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const uploadExcelBpmlApi = async (data) => {
  const res = await client.post('/upload/excel/bpmls', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const uploadCaseTemplateApi = async (data) => {
  const res = await client.post('/upload/excel/cases', data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const updateBpmlEut = async (id, data) => {
  const res = await client.put('/bpmls/update/eut/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const deleteTest = async (id, data) => {
  const res = await client.put('/bpmls/tests/delete/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const deleteQuestion = async (id, data) => {
  const res = await client.put('/bpmls/questions/delete/' + id, data, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

const resetBpml = async (code) => {
  const res = await client.delete(`/bpmls/reset?code=${code}`, config)
  if (res) {
    return res.data
  } else {
    return []
  }
}

export {
  getCasesByBpml,
  getAssesmentBpmls,
  postCaseByBpml,
  getQuestionByBpml,
  getQuestions,
  postQuestion,
  resetBpml,
  updateBpmlVideo,
  getAssesmentsQuery,
  getAssesments,
  addAssesments,
  getOneAssesment,
  updateTest,
  updateCase,
  uploadCaseTemplateApi,
  deleteAssesment,
  deleteQuestion,
  updateAssesment,
  updateBpmlBodyFile,
  updateBpmlEut,
  deleteTest,
  getAssesmentsQueryUser,
  getAssesmentsQueryAdmin,
  getTestByCase,
  updateQuestion,
  getAssesmentQuestions,
  postTestByCase,
  uploadExcelBpmlApi,
  uploadExcelQuestionApi,
}
