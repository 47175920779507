import fuzzysort from 'fuzzysort'

export default {

  fetchProjectSuccess(state, data) {
    // for (const project in data) {
    //   project.company = project.assesment.project.company
    // }
    state.projectListLoading = false
    state.projectData = data;
    state.filteredProjectData = data;
  },

  IFrameBefore (state) {
    state.iframeLoading = true
  },

  IFrameFolderUpdate (state, data) {
    state.iframefolderloaded = data
  },

  iFrameUpdate(state, data) {
    console.log(data)
    state.iframeUpdate = !state.iframeUpdate
    state.iframeLoading = false;
  },

  fetchAssesmentsSuccess(state, data) {
    state.assesmentList = data
    state.filteredAssesments = data
  },

  insertAssesmentsSuccess(state, data) {
    state.assesmentList = data.assesments
    state.filteredAssesments = data.assesments
  },

  fetchQuestionsSuccess(state, data) {
    console.log(data, 'bpmlquestion')
    state.bpmlQuestions = data;
  },

  fetchCommentsSuccess(state, data) {
    console.log(data, 'comments')
    state.commentsUpdate = state.commentsUpdate++
    state.comments = data;
  },

  fetchProjectFilesSuccess(state, data) {
    state.projectFiles = data
    console.log(data, 'projectfiles')
  },

  fetchCasesSuccess(state, data) {
    state.bpmlCases = data
    state.selectedCaseId = data[0]._id
    state.selectedCase = data[0].case
    state.selectedCaseObj = data[0]
  },

  fetchUploadCaseSuccess(state, data) {

    state.selectedCase = data[data.length - 1].case
    state.bpmlCases = data.filter(x => x.event === data[data.length - 1].event)
    state.testCategory = data[data.length - 1].event
  },

  fetchTestsSuccess(state, data) {
    const newData = []
    for (const index in data) {
      data[index].key = index
      newData.push(data[index])
    }
    state.bpmlTests = newData
  },

  fetchCasesFailed(state) {
    state.selectedCase = null
    state.selectedCaseId = null
    state.selectedCase = null
    state.selectedCaseObj = null
    state.bpmlTests = null
  },

  fetchBpmlProjectSuccess(state, data) {
    data.sort((a, b) => {
      if (a.bpmlCode < b.bpmlCode) {
        return -1;
      } else if (a.bpmlCode > b.bpmlCode) {
        return 1;
      } else {
        return 0;
      }
    });
    state.bpmls = data;
    state.filteredBpmls = data;
    state.bpmlLoading = false;
    console.log(state.bpmls, 'assesmentBPMLS')
  },

  fetchBpmlL2s(state, data) {
    state.bpmlL2s = data
  },

  fetchAssesmentQuestionsSuccess(state, data) {
    state.assesmentQuestions = data
    console.log(data, 'questionassesment')
  },

  fetchOneAssesment(state, data) {
    state.singleAssesment = data
  },

  industrySearch(state, data) {
    let rawIndustriesList = state.industries
    if (data.length > 0) {
      console.log(data, 'user')
      const results = fuzzysort.go(data, rawIndustriesList, {key:'industry'})
      console.log(results, 'results')
      const newData = []
      for (const assesment of results) {
        newData.push(assesment.obj)
      }
      state.filteredIndustries = newData
    } else {
      state.filteredIndustries = rawIndustriesList
    }
  },

  fetchBpmlProjectBegin(state) {
    state.bpmlLoading = true;
  },

  updateBpmlBodySuccess(state, data) {
      const selectedBpml = state.selectedBpml
      for (const bpml of state.filteredBpmls) {
        if (bpml.bpmlCode === selectedBpml) {
          const bpmlBodyFile = data.bpmlBodyFile
          bpml.bpmlBodyFile = bpmlBodyFile
        }
      }
  },

  updateBpmlVideoSuccess(state, data) {
    const selectedBpml = state.selectedBpml
    const bpmls = [...state.filteredBpmls]
    for (const bpml of bpmls) {
      if (bpml.bpmlCode === selectedBpml) {
        const bpmlVideoFile = data.bpmlVideoFile
        const bpmlVideoName = data.bpmlVideoName
        bpml.bpmlVideoFile = bpmlVideoFile
        bpml.bpmlVideoName = bpmlVideoName
      }
    }
    state.filteredBpmls = bpmls
  },

  updateBpmlEutSuccess(state, data) {
    const selectedBpml = state.selectedBpml
    const bpmls = [...state.filteredBpmls]
    for (const bpml of bpmls) {
      if (bpml.bpmlCode === selectedBpml) {
        const bpmlEutFile = data.bpmlEutFile
        const bpmlEutName = data.bpmlEutName
        bpml.bpmlEutFile = bpmlEutFile
        bpml.bpmlEutName = bpmlEutName
      }
    }
    state.filteredBpmls = bpmls
  },

  fetchOneProject(state, data) {
    console.log(data, 'oneproject')
    state.singleData = data
  },

  fetchIndustriesSuccess(state, data) {
    for (const index in data) {
      data[index].id = index
    }
    state.industries = data;
    state.filteredIndustries = data;
  },

  addProjectSuccess(state, data) {
    state.projectData.push(data)
  },

  filterByL2(state, data) {
    console.log(state.selectedModule, 'selectedmodule')
    let filteredBpml = state.bpmls.filter(
      (bpml) => bpml.bpmlL2 === data
    );
    if (state.selectedModule) {
      filteredBpml = state.bpmls.filter(
        (bpml) => bpml.bpmlL2 === data && bpml.module === state.selectedModule
      );
    }
    state.filteredBpmls = filteredBpml
  },

  filterByModules (state, data) {
    if (data) {
      let filteredBpml = state.bpmls.filter(
        (bpml) => bpml.module === data
      );
      console.log(state.selectedL2, 'selectedL2')
      // if (state.selectedL2) {
      //   filteredBpml = state.bpmls.filter(
      //     (bpml) => bpml.module === data && bpml.bpmlL2 === state.selectedL2
      //   );
      // }
      state.filteredBpmls = filteredBpml
    } else {
      state.filteredBpmls = state.bpmls
    }
  },

  projectSearch(state, data) {
    let rawProjectList = state.projectData
    console.log(rawProjectList[0].projectId, 'projectId')
    if (data.length > 0) {
      console.log(data, 'assesment')
      for (const project of rawProjectList) {
        project.projectId = project.projectId.toString();
      }
      // if (state.selectedL2) {
      //   rawBpml = rawBpml.filter(bpml => bpml.bpmlL2 === state.selectedL2)
      // }
      const results = fuzzysort.go(data, rawProjectList, {key:'company'})
      console.log(results, 'results')
      const newData = []
      for (const project of results) {
        newData.push(project.obj)
      }
      newData.sort((a, b) => {
        if (a.bpmlCode < b.bpmlCode) {
          return -1;
        } else if (a.bpmlCode > b.bpmlCode) {
          return 1;
        } else {
          return 0;
        }
      });
      state.filteredProjectData = newData
    } else {
      state.filteredProjectData = rawProjectList
    }
  },

  assesmentSearch(state, data) {
    let rawAssesmentList = state.assesmentList
    console.log(rawAssesmentList[0].assesmentId, 'rawAssesmentList')
    if (data.length > 0) {
      console.log(data, 'assesment')
      for (const assesment of rawAssesmentList) {
        assesment.assesmentId = assesment.assesmentId.toString();
      }
      // if (state.selectedL2) {
      //   rawBpml = rawBpml.filter(bpml => bpml.bpmlL2 === state.selectedL2)
      // }
      const results = fuzzysort.go(data, rawAssesmentList, {key:'project.company'})
      console.log(results, 'results')
      const newData = []
      for (const assesment of results) {
        newData.push(assesment.obj)
      }
      newData.sort((a, b) => {
        if (a.bpmlCode < b.bpmlCode) {
          return -1;
        } else if (a.bpmlCode > b.bpmlCode) {
          return 1;
        } else {
          return 0;
        }
      });
      state.filteredAssesments = newData
    } else {
      state.filteredAssesments = rawAssesmentList
    }
  },

  filterByBpml(state, data) {
    let rawBpml = state.bpmls
    if (state.selectedModule) {
      rawBpml = state.bpmls.filter(bpml => bpml.module === state.selectedModule)
    }
    if (data.length > 0) {
      // if (state.selectedL2) {
      //   rawBpml = rawBpml.filter(bpml => bpml.bpmlL2 === state.selectedL2)
      // }
      const results = fuzzysort.go(data, rawBpml, {key:'bpmlCode'})
      const newData = []
      for (const bpml of results) {
        newData.push(bpml.obj)
      }
      state.selectedL2 = ""
      newData.sort((a, b) => {
        if (a.bpmlCode < b.bpmlCode) {
          return -1;
        } else if (a.bpmlCode > b.bpmlCode) {
          return 1;
        } else {
          return 0;
        }
      });
      state.filteredBpmls = newData
    } else {
      state.filteredBpmls = rawBpml
    }
  },

  loadSfdtSuccess(state, data) {
    state.sfdt = data
  },

  singleProjectBegin(state) {
    state.loading = true;
  },

  singleProjectSuccess(state, data) {
    state.loading = false;
    state.singleData = data;
  },

  singleProjectErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  filterProjectBegin(state) {
    state.loading = true;
  },

  filterProjectSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  filterProjectErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  sortingProjectBegin(state) {
    state.loading = true;
  },

  sortingProjectSuccess(state, data) {
    state.loading = false;
    state.data = data;
  },

  sortingProjectErr(state, err) {
    state.loading = false;
    state.error = err;
  },
};
